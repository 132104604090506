<template>
  <discovery-form
    v-if="discovery"
    action="update"
    :loading="loading"
    :updating="updating"
    :data="discovery"
    :error-messages="errorMessages"
    :handle-submit="handleFormSubmit"
    :handle-discard="handleDiscard"
  />
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, computed, provide, getCurrentInstance } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import useDiscoveryView from './useDiscoveryView'

import DiscoveryForm from './discovery-form/DiscoveryForm.vue'

export default {
  components: {
    DiscoveryForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route } = useRouter()

    const {
      discovery,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useDiscoveryView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    // const discoveryDataLocal = computed(() => discovery.value)

    const handleFormSubmit = formData => updateData(formData)
    const handleDiscard = () => vm.$router.replace({ name: 'discovery-view', params: { id: route.value.params.id } })

    return {
      discovery,
      loading,
      updating,
      updateData,

      errorMessages,

      // discoveryDataLocal,

      handleFormSubmit,
      handleDiscard,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
